<!-- App.vue -->
<template>
  <div class="app" :class="{ 'mobile': portfolioStore.isMobile }">
    <div v-if="portfolioStore.isLoading" class="loading">
      Loading...
    </div>
    <template v-else>
      <router-view v-slot="{ Component }">
        <transition :name="transitionName" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, provide, watch, nextTick } from 'vue'
import { usePortfolioStore } from '@/stores/portfolio'
import { useModularScale, useSemanticColors } from '@/composables/useStyleVariables'
import { useRoute, useRouter } from 'vue-router'

// Route handling
const portfolioStore = usePortfolioStore();
const router = useRouter();
const route = useRoute();

// Style 
const { scale } = useModularScale();
const { bgColors, textColors, buttonColors } = useSemanticColors();
// Provide global values
provide('scale', scale);

const transitionName = ref('fade');

// Function to attempt scroll restoration multiple times
const attemptScrollRestore = async (savedPosition, attempts = 5, delay = 100) => {
  console.log("App attemptScrollRestore attempt", 6 - attempts, "of 5");
  
  if (attempts <= 0) {
    console.log("App attemptScrollRestore failed after all attempts");
    return;
  }

  window.scrollTo({
    left: savedPosition.x,
    top: savedPosition.y,
    behavior: 'smooth'
  });

  // Check if scroll position was set correctly
  if (Math.abs(window.scrollY - savedPosition.y) > 1) {
    console.log("App attemptScrollRestore scroll position not set correctly, trying again");
    await new Promise(resolve => setTimeout(resolve, delay));
    await attemptScrollRestore(savedPosition, attempts - 1, delay);
  } else {
    console.log("App attemptScrollRestore successful");
  }
};

// Save scroll position before route changes
router.beforeEach((to, from, next) => {
  console.log("App router.beforeEach from", from)
  // Save the scroll position for the current route before navigating
  portfolioStore.setRouteScrollPosition({
    path: from.path,
    global: {
      x: window.scrollX,
      y: window.scrollY
    }
  });
  next();
});

// Handle scroll position restoration after route changes
watch(
  () => route.path,
  async (newPath, oldPath) => {
    console.log("App watch route.path newPath", newPath);
    console.log("App watch route.path oldPath", oldPath);
    
    // Wait for initial DOM update
    await nextTick();
    
    // Add a small delay to ensure components have mounted
    await new Promise(resolve => setTimeout(resolve, 50));
    
    console.log("App watch route.path portfolioStore.routeScrollPosition", portfolioStore.routeScrollPosition);
    
    // Check if we have a saved position for this route
    const savedPosition = portfolioStore.routeScrollPosition[newPath]?.global;
    
    console.log("App watch route.path savedPosition", savedPosition);
    
    if (savedPosition) {
      console.log("App watch route.path restoring saved position");
      await attemptScrollRestore(savedPosition);
    } else {
      console.log("App watch route.path no saved position, scrolling to top");
      window.scrollTo(0, 0);
    }
  }
);

// Lifecycle hooks
const initializeApp = async (retries = 3) => {
  try {
    console.log("App initializeApp")
    portfolioStore.isLoading = true; // Set loading at start of initialization

    // Wait for portfolio data
    if (!portfolioStore.isLoaded) {
      await portfolioStore.fetchPortfolioData()
    }

    // Initial viewport setup
    portfolioStore.updateViewport()
    portfolioStore.isLoading = false; // Set loading to false after everything is ready

  } catch (error) {
    if (retries > 0) {
      console.error(`Error initializing app, retrying... (${retries} attempts left)`)
      await new Promise(resolve => setTimeout(resolve, 1000))
      return initializeApp(retries - 1)
    } else {
      console.error('Failed to initialize app after multiple attempts:', error)
      portfolioStore.isLoading = false; // Make sure to clear loading state even on error
      router.push('/404')
    }
  }
}

watch(
  () => portfolioStore.overflowHidden,
  (hide) => {
    console.log("App Set Hide overflow to", hide);
    const overflowValue = hide ? 'hidden' : 'auto'
    document.documentElement.style.overflowY = overflowValue
    document.body.style.overflowY = overflowValue

    document.ontouchmove = (e) => {
      if (hide) {
        e.preventDefault()
      }
    }
  }
)

onMounted(async () => {
  await initializeApp();
  window.addEventListener('resize', () => portfolioStore.updateViewport());
  window.addEventListener('scroll', () => portfolioStore.handleScroll());
  portfolioStore.handleScroll();
});

onUnmounted(() => {
  // Clean up listeners
  window.removeEventListener('resize', portfolioStore.updateViewport);
  window.removeEventListener('scroll', portfolioStore.handleScroll);
});
</script>

<style>
/* Non-scoped (global) styles here */
@import url("https://use.typekit.net/mia7dtk.css");

* {
  font-family: "futura-pt", sans-serif;
  font-weight: 300;
  font-style: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: v-bind('scale.text0 + "px"');
  height: auto;
  min-height: 100%;
  overflow-y: auto;
}

ul {
  list-style-type: none;
  color: inherit;
}

li {
  text-decoration: none;
  color: inherit;
}

a {
  font-size: v-bind('scale.text0 + "px"');
  font-family: "franklin-gothic-condensed", 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  display: inline;
  text-decoration: none;
  text-align: left;
  color: v-bind('textColors.accent');
  cursor: pointer;
  pointer-events: auto;
}

a:hover {
  color: v-bind('buttonColors.hover');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "futura-pt", sans-serif;
  font-weight: 300;
  font-style: normal;
}

p,
li {
  font-size: v-bind('scale.text0 + "px"');
  font-family: "franklin-gothic-condensed", 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

h1 {
  font-size: v-bind('scale.text6 + "px"');
}

h2 {
  font-size: v-bind('scale.text5 + "px"');
}

h3 {
  font-size: v-bind('scale.text4 + "px"');
  line-height: v-bind('scale.text4 + "px"');
}

h4 {
  font-size: v-bind('scale.text3 + "px"');
}

h5 {
  font-size: v-bind('scale.text2 + "px"');
}

h6 {
  font-size: v-bind('scale.text1 + "px"');
}

.mobile-spacer {
  width: 100%;
  height: v-bind('portfolioStore.mobileSpacerHeight + "px"');
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: v-bind('bgColors.primary');
  color: v-bind('textColors.primary');
  font-size: v-bind('scale.text2 + "px"');
}
</style>