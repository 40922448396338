<!-- AppLayout.vue -->
<template>
  <div id="app" :style="appStyle">
    <div :style="mainContainerStyle" class="app_main_container">
      <div ref="navMenu" :style="navMenuStyle">
        <NavMenu :links="sectionLinks" @first-link-baseline="handleFirstLinkBaseline" />
      </div>

      <div ref="wordMark" class="app_wordMark" :style="wordmarkStyle">
        <WordMark></WordMark>
      </div>

      <div class="app_content_container" :style="contentContainerStyle">
        <Suspense>
          <template #default>
            <router-view v-slot="{ Component }" :key="$route.name">
              <component :is="Component" :key="$route.fullPath" />
            </router-view>
          </template>
          <template #fallback>
            <div>Loading...</div>
          </template>
        </Suspense>
      </div>
    </div>

    <footer ref="footer" class="app_footer" :style="footerStyle">
      <p>&copy; {{ currentYear }} David Moreau. All rights reserved.</p>
    </footer>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import WordMark from '@/components/interactive/WordMark.vue';
import NavMenu from '@/components/navigation/NavMenu.vue';
import { useRoute } from 'vue-router';
import { useModularScale, useSemanticColors } from '@/composables/useStyleVariables';
import { usePortfolioStore } from '@/stores/portfolio';

// Props & Route
const route = useRoute();
// const router = useRouter();


const portfolioStore = usePortfolioStore();
const { scale } = useModularScale();
const { bgColors, textColors } = useSemanticColors();

// Local state
const navMenu = ref(null);
const wordMark = ref(null);
const sectionLinks = ref([]);
const textBaseline = ref(70);
const wordMarkHeight = ref(100);
const currentYear = computed(() => new Date().getFullYear());

// Portfolio data
const marketObject = computed(() => {
  return portfolioStore.portfolioData.getMarketObj(portfolioStore.marketId);
});

// Computed styles

const styleMargins = computed(() => {

let topMargin = scale.value.text0 + "px";
if (portfolioStore.isDesktop) topMargin = scale.value.text2;
if (portfolioStore.isLaptop) topMargin = scale.value.text0;
if (portfolioStore.isTablet) topMargin = scale.value.text0000;
if (portfolioStore.isMobile) topMargin = scale.value.text0000;

let sideMargin = scale.value.text2;
if (portfolioStore.isDesktop) sideMargin = scale.value.text2;
if (portfolioStore.value) sideMargin = scale.value.text0;
if (portfolioStore.value) sideMargin = scale.value.text000;
if (portfolioStore.value) sideMargin = scale.value.text000;

// console.log(`Top Margin: ${topMargin} | Side Margin: ${sideMargin}`)
return {
  topMargin: topMargin,
  sideMargin: sideMargin
}
});

const appStyle = computed(() => {

  const baseStyle = {
    width: '100%',
    // minHeight: '100vh',
    height: portfolioStore.isOverflowHidden ? '`${viewportHeight.value}px`' : 'unset',
    minHeight: portfolioStore.isOverflowHidden ? `${portfolioStore.viewportDims.height}px` : '100vh',
    display: 'flex',
    flexDirection: 'column'
  }

  return baseStyle;

});

const mainContainerStyle = computed(() => {

  const topRow = portfolioStore.isMobile ? scale.value.text3 : textBaseline.value;

  let rowTemplate = `${styleMargins.value.topMargin}px ${topRow}px 1fr`;
  const colTemplate = `${styleMargins.value.sideMargin}px ${portfolioStore.navMenuDims.width}px 1fr ${styleMargins.value.sideMargin}px`

  const baseStyle = {
    display: 'grid',
    flex: '1',
    gridTemplateRows: rowTemplate,
    gridTemplateColumns: colTemplate,
    width: '100%',
    minHeight: '100vh',
    overflow: 'visible'
  }

  return baseStyle;
}
);

const navMenuStyle = computed(() => {

  const baseStyle = {
    // gridRow: ' 2 / 3',
    // gridColumn: '2 / -1',
    position: 'fixed',
    top: `${styleMargins.value.topMargin}px`,
    left: styleMargins.value.sideMargin + "px",
    width: 'fit-content',
    maxWidth: '40%',
    height: 'fit-content',
    zIndex: 4999,
  }

  return baseStyle

});

const wordmarkStyle = computed(() => {

  let stickyTop;
  if (portfolioStore.isMobile || portfolioStore.isTablet) stickyTop = `${styleMargins.value.topMargin}px`;
  else stickyTop = `${styleMargins.value.topMargin + textBaseline.value - wordMarkHeight.value}px`

  const baseStyle = {
    // gridRow: '2 / 3',
    // gridColumn: ' 2 / -2',
    position: 'fixed',
    top: stickyTop,
    right: styleMargins.value.sideMargin + "px",
    justifySelf: 'end',
    alignSelf: portfolioStore.isMobile ? 'start' : 'end',
    width: 'fit-content',
    height: 'fit-content',
    zIndex: 4999,
  }

  return baseStyle;
});

const contentContainerStyle = computed(() => {
  let gridRow;
  let gridColumn;

  console.log("AppLayout contentContainerStyle Extended", portfolioStore.extended)

  if (portfolioStore.isMobile) {
    gridRow = '3 / -1';
    gridColumn = '1 / -1';
  }

  if (portfolioStore.isTablet) {
    if (portfolioStore.extended) {
      gridRow = '1 / -1';
      gridColumn = '1 / -1';
    } else {
      gridRow = '3 / -1'
      gridColumn = '2 / -2';
    }
  }

  if (portfolioStore.isDesktop || portfolioStore.isLaptop) {
    if (portfolioStore.extended) {
      gridRow = '1 / -1';
      gridColumn = '1 / -1';
    } else {
      gridRow = '3 / -1';
      gridColumn = '3 / -2';
    }

  }

  const baseStyle = {
    overflow: 'visible',
    gridRow: gridRow,
    gridColumn: gridColumn,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  };

  return baseStyle;

});

const footerStyle = computed(() => ({
  height: `${scale.value.text2}px`,
  position: 'sticky',
  bottom: 0,
  left: 0,
  width: '100%',
  zIndex: 4998,
}));

const handleFirstLinkBaseline = (baseline) => {
  console.log("handleFirstLinkBaseline", baseline);
  if (portfolioStore.isMobile) {
    textBaseline.value = scale.value.text0;
  } else {
    textBaseline.value = baseline;
  }
};

const updateDimensions = () => {
  if (navMenu.value) {
    console.log("AppLayout Setting NavMenuWidth to ", navMenu.value.offsetWidth)
    portfolioStore.updateNavMenuDims({width: navMenu.value.offsetWidth, height: navMenu.value.offsetHeight})
  }
  if (wordMark.value) {
    wordMarkHeight.value = wordMark.value.offsetHeight;
  }
};

// Link generation
const createLinkArray = () => {
  console.log("AppLayout createLinkArray ");
  const linkArray = [];

  if (!marketObject.value) return;

  marketObject.value.sections.forEach(section => {
    console.log("AppLayout section", section);

    const sectionObj = portfolioStore.portfolioData.getSectionObj(section);
    if (sectionObj) {
    console.log("AppLayout section exists", section);

      linkArray.push({
        path: `/${portfolioStore.marketId}${sectionObj.routerPath}`,
        text: sectionObj.displayName.trim()
      });
    }
  });

  sectionLinks.value = linkArray;
};

// Watchers
watch(() => portfolioStore.marketId, (newMarket) => {
  if (newMarket) {
    createLinkArray();
  }
}, { immediate: true });

watch(
  () => portfolioStore.viewportDims,
  () => {
    updateDimensions();
  },
  { deep: true }
);

// FOR DEBUGGING
// Add this to your script setup
watch(() => route.params, (params) => {
  console.log('Route params changed:', params);
}, { immediate: true });

watch(() => route.matched, (matched) => {
  console.log('Matched routes:', matched);
}, { immediate: true });

// Lifecycle hooks
onMounted(() => {
  createLinkArray();
  updateDimensions();
});
</script>

<style scoped>
.content {
  min-height: 100%;
}

footer {
  padding: v-bind('scale.text000 + "px"');
  text-align: start;
  background-color: v-bind('bgColors.lightTranslucent');
  color: v-bind('textColors.bgLightFaint');
}

footer p {
  font-size: v-bind('scale.text00 + "px"');
}
</style>