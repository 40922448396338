// stores/portfolio.js
import { defineStore } from 'pinia'
import PortfolioData from '@/assets/js/PortfolioData'
import breakpoints from '@/assets/json/portfolioBreakpoints.json'

export const usePortfolioStore = defineStore('portfolio', {
    state: () => ({
        // Portfolio data
        portfolioJson: null,
        portfolioData: null,
        error: null,
        currentMarket: null,
        isLoading: true,

        // Viewport and device state
        viewportDims: {
            width: 0,
            height: 0,
            aspectRatio: 0
        },
        
        // Device breakpoints
        isMobile: false,
        isTablet: false,
        isLaptop: false,
        isDesktop: false,

        // Font settings
        fontSize: 20,
        fontMult: 1.414,

        // Scroll state
        isScrolled: false,
        scrollDirection: null,
        lastScrollPosition: 0,

        // Overflow and Layout control
        overflowHidden: false,
        extended: false,
        navMenuDims:{width:null, height: null},
        resetGlobalScrollPosition: false,
        routeScrollPosition: {} //path:{global: {x:x, y:y}, local:{x:x, y:y} }
    }),

    getters: {
        isLoaded: (state) => state.portfolioJson !== null && state.portfolioData !== null,
        marketId: (state) => state.currentMarket,
        mobileSpacerHeight: (state) => (state.isMobile || state.isTablet) ? 100 : 0,
        isOverflowHidden: (state) => state.overflowHidden
    },

    actions: {
        async fetchPortfolioData() {
            this.isLoading = true;
            this.error = null;

            try {
                console.log('usePortfolioStore: Fetching portfolio data...');
                const response = await fetch('/json/portfolio.json');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log('usePortfolioStore: Fetched portfolio data:', data);

                this.portfolioJson = data;
                this.portfolioData = new PortfolioData(data);
                return data;
            } catch (error) {
                console.error('Store: Error fetching portfolio data:', error);
                this.error = error;
                throw error;
            } 
        },

        setCurrentMarket(market) {
            this.currentMarket = market;
        },

        updateViewport() {
            console.log("usePortfolioStore updateViewport");

            const width = window.innerWidth;
            const height = window.innerHeight;

            this.viewportDims = {
                width,
                height,
                aspectRatio: width / height
            };

            console.log("usePortfolioStore viewportDims after", this.viewportDims);


            // Update device breakpoints using imported breakpoints.json
            this.isMobile = width < breakpoints.mobile;
            this.isTablet = width >= breakpoints.mobile && width < breakpoints.tablet;
            this.isLaptop = width >= breakpoints.tablet && width < breakpoints.laptop;
            this.isDesktop = width >= breakpoints.laptop;

            // Update font size based on breakpoints
            if (this.isDesktop) {
                this.fontSize = 20;
                this.fontMult = 1.414;
            } else if (this.isLaptop) {
                this.fontSize = 18;
                this.fontMult = 1.414;
            } else if (this.isTablet) {
                this.fontSize = 18;
                this.fontMult = 1.333;
            } else if (this.isMobile) {
                this.fontSize = 16;
                this.fontMult = 1.250;
            } else {
                this.fontSize = 20;
                this.fontMult = 1.414;
            }
        },

        handleScroll() {
            const currentScrollPosition = window.scrollY;
            this.isScrolled = currentScrollPosition > 0;
            this.scrollDirection = currentScrollPosition > this.lastScrollPosition ? 'down' : 'up';
            this.lastScrollPosition = currentScrollPosition;
        },

        setOverflowHidden(value) {
            console.log("usePortfolioStore setOverflowHidden to", value);
            console.log("usePortfolioStore setOverflowHidden before", this.overflowHidden);
            this.overflowHidden = value;
            console.log("usePortfolioStore setOverflowHidden after", this.overflowHidden);
        },

        toggleOverflowHidden() {
            this.setOverflowHidden(!this.overflowHidden);
        },

        updateNavMenuDims(dimObj) {
            console.log("usePortfolioStore updateNavMenuDims dimObj", dimObj)
            this.navMenuDims.width = dimObj.width;
            this.navMenuDims.height = dimObj.height;
        },

        setExtended(value) {
            this.extended = value;
        },
        
        setRouteScrollPosition(scrollPosObj) {
            // {path: path, global: {x:x, y:y}, local:{x:x, y:y} }
            try {
                // Validate scrollPosObj exists and has required properties
                if (!scrollPosObj || typeof scrollPosObj !== 'object') {
                    throw new Error('Invalid scrollPosObj: must be an object');
                }

                // Validate required path property
                if (!scrollPosObj.path || typeof scrollPosObj.path !== 'string') {
                    throw new Error('Invalid path: must be a non-empty string');
                }

                // Validate at least one of global or local exists
                if (typeof scrollPosObj.global === 'undefined' && typeof scrollPosObj.local === 'undefined') {
                    throw new Error('Either global or local scroll position must be provided');
                }

                // Ensure this.routeScrollPosition is initialized as an object
                if (!this.routeScrollPosition || typeof this.routeScrollPosition !== 'object') {
                    this.routeScrollPosition = {};
                }

                // Initialize path object if it doesn't exist
                if (!this.routeScrollPosition[scrollPosObj.path]) {
                    this.routeScrollPosition[scrollPosObj.path] = {};
                }

                // Update only provided values
                if (typeof scrollPosObj.global !== 'undefined') {
                    this.routeScrollPosition[scrollPosObj.path].global = scrollPosObj.global;
                }
                if (typeof scrollPosObj.local !== 'undefined') {
                    this.routeScrollPosition[scrollPosObj.path].local = scrollPosObj.local;
                }
            } catch (error) {
                console.error('Error updating scroll position:', error.message);
                throw error;
            }
        },

        getRouteScrollPosition(routePath) {
            try {
                // Validate input
                if (!routePath || typeof routePath !== 'string') {
                    console.warn('Invalid route path provided to getRouteScrollPosition');
                    return null;
                }
        
                // Return whatever is stored, even if null
                return this.routeScrollPosition[routePath];
            } catch (error) {
                console.error('Error retrieving route scroll position:', error);
                return null;
            }
        }
    }
})