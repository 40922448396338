<template>
    <div class="fourohfour-container">
        <svg class="four-oh-four" width="500" height="250" viewBox="0 0 500 250">
            <path d="M139.75,144.96h21.7v35.64h-21.7v33.76h-42.87v-33.76H10.33v-29.74L79.19,35.64h60.56v109.32ZM96.88,144.96v-72.88l-42.07,72.88h42.07Z"/>
            <path d="M297.83,198.02c-13.93,13.67-31.88,20.36-49.3,20.36s-35.37-6.7-49.3-20.36c-16.08-15.81-26.53-41.53-26.53-72.88s10.45-57.34,26.53-73.15c13.93-13.67,31.89-20.36,49.3-20.36s35.37,6.7,49.3,20.36c16.08,15.81,26.53,41.53,26.53,73.15s-10.45,57.07-26.53,72.88ZM234.33,78.78c-9.65,8.31-15.54,30.01-15.54,46.62,0,14.2,5.09,37.51,15.54,46.62,3.48,3.21,8.84,5.63,14.2,5.63s10.72-2.41,14.2-5.63c9.91-8.57,15.54-31.08,15.54-46.35s-6.16-38.58-15.54-46.89c-3.48-3.21-8.84-5.63-14.2-5.63s-10.72,2.41-14.2,5.63Z"/>
            <path d="M467.97,144.96h21.7v35.64h-21.7v33.76h-42.87v-33.76h-86.55v-29.74l68.86-115.22h60.56v109.32ZM425.09,144.96v-72.88l-42.07,72.88h42.07Z"/>
        </svg>
        <div class="snark">
            <h3> Hmmm. . . Haven't Designed That One Yet</h3>
            <p><span><a href="mailto:moreaucreativeinc@gmail.com" class="email-link">Get in Touch</a> and I'll get right on it.</span></p>
        </div>
    </div>
</template>

<script setup>

import { onMounted, defineEmits } from 'vue';
import { useModularScale, useSemanticColors } from '@/composables/useStyleVariables';

const { scale } = useModularScale();
const { textColors } = useSemanticColors();

const emit = defineEmits(['mounted']);

onMounted(() => {
  emit('mounted', 'NotFound');
});

</script>

<style scoped>
.fourohfour-container {
    width: 80%;
    height: 85%;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: center;
    align-content: start;
    padding: 15% 10% 15% 10%;
    gap: v-bind('scale.text1 + "px"');
}

.four-oh-four {
    width: 100%;
    height: auto;
}

.snark {
    display: flex;
    flex-direction: column;
    gap: v-bind('scale.text1 + "px"');
}

/* .sub {
    display: flex;
    flex-direction: row;
} */

.snark a, .snark p {
    font-size: v-bind('scale.text0 + "px"');
    font-family: "franklin-gothic-condensed", 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.snark a {
    color: v-bind('textColors.accent');
    font-weight: 500;
}
</style>