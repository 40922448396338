// router.js
import { createRouter, createWebHistory } from 'vue-router'
import { useRoute } from 'vue-router'
import { h } from 'vue';
import { usePortfolioStore } from '@/stores/portfolio'
import AppLayout from '@/views/AppLayout.vue'
import NotFound from '@/views/ErrorView.vue'

// SEO helper function to update meta tags
const updateMetaTags = (to, portData) => {
    try {
        if (!portData) {
            throw new Error('Portfolio data is required for meta tag updates')
        }

        const market = to.params.market ? portData.markets[to.params.market] : null
        const section = to.params.section ? portData.sections[to.params.section] : null
        const project = to.params.project ? portData.projects[to.params.project] : null

        // Build title and description based on route
        let title = 'Portfolio'
        let description = 'Welcome to my portfolio'
        let imageUrl = '/default-og-image.jpg' // Default OG image
        const baseUrl = window.location.origin

        if (market) {
            title = market.displayName || to.params.market
            description = market.description || description

            if (section) {
                title = `${section.displayName || to.params.section} - ${title}`
                description = section.description || description
                imageUrl = section.ogImage || imageUrl

                if (project) {
                    title = `${project.displayName || to.params.project} - ${title}`
                    description = project.description || description
                    imageUrl = project.ogImage || imageUrl
                }
            }
        }

        // Handle 404 page
        if (to.name === 'NotFound') {
            title = 'Page Not Found'
            description = 'The requested page could not be found.'
        }

        // Clean up existing meta tags
        const existingMetaTags = document.querySelectorAll('meta[data-vue-router-controlled]')
        existingMetaTags.forEach(tag => tag.remove())

        // Clean up existing JSON-LD
        const existingJsonLd = document.querySelectorAll('script[type="application/ld+json"]')
        existingJsonLd.forEach(tag => tag.remove())

        // Update meta tags
        const metaTags = {
            // Basic meta tags
            'description': { name: 'description', content: description },
            'robots': { name: 'robots', content: 'index, follow' },
            
            // Canonical URL
            'canonical': { rel: 'canonical', href: window.location.href },
            
            // Open Graph meta tags
            'og:title': { property: 'og:title', content: title },
            'og:description': { property: 'og:description', content: description },
            'og:type': { property: 'og:type', content: 'website' },
            'og:image': { property: 'og:image', content: `${baseUrl}${imageUrl}` },
            'og:url': { property: 'og:url', content: window.location.href },
            
            // Twitter Card meta tags
            'twitter:card': { name: 'twitter:card', content: 'summary_large_image' },
            'twitter:title': { name: 'twitter:title', content: title },
            'twitter:description': { name: 'twitter:description', content: description },
            'twitter:image': { name: 'twitter:image', content: `${baseUrl}${imageUrl}` }
        }

        // Add language meta tags if available
        if (market?.language) {
            metaTags['language'] = { name: 'language', content: market.language }
            metaTags['og:locale'] = { property: 'og:locale', content: market.language }
        }

        // Update or create meta tags
        Object.entries(metaTags).forEach(([key, meta]) => {
            let tag
            if (meta.rel === 'canonical') {
                tag = document.querySelector('link[rel="canonical"]')
                if (!tag) {
                    tag = document.createElement('link')
                    tag.setAttribute('rel', 'canonical')
                    document.head.appendChild(tag)
                }
                tag.setAttribute('href', meta.href)
            } else {
                tag = document.createElement('meta')
                if (meta.name) tag.setAttribute('name', key)
                if (meta.property) tag.setAttribute('property', key)
                tag.setAttribute('content', meta.content)
                // Mark as controlled by vue-router
                tag.setAttribute('data-vue-router-controlled', '')
                document.head.appendChild(tag)
            }
        })

        // Update document title
        document.title = title

        // Add JSON-LD structured data
        const structuredData = {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: title,
            description: description,
            image: `${baseUrl}${imageUrl}`,
            url: window.location.href,
            inLanguage: market?.language || 'en'
        }

        const jsonLdScript = document.createElement('script')
        jsonLdScript.type = 'application/ld+json'
        jsonLdScript.textContent = JSON.stringify(structuredData)
        document.head.appendChild(jsonLdScript)

    } catch (error) {
        console.error('Error updating meta tags:', error)
        // Set fallback title and description
        document.title = 'Portfolio'
        const fallbackDescription = document.querySelector('meta[name="description"]')
        if (fallbackDescription) {
            fallbackDescription.setAttribute('content', 'Welcome to my portfolio')
        }
    }
};

const loadProps = (type, id, portData) => {
    const config = portData[type][id];
    if (!config) return {};

    function processCircleGridGallery(section) {
        if (!section || !portData) {
            throw new Error('Section and portfolio data are required');
        }
    
        const galleries = Object.values(section.galleries ?? {});
    
        const projectList = galleries.flatMap(gallery => {
            const projects = gallery.projects ?? {};
            
            return Object.entries(projects).map(([projectKey, projectDetails]) => {
                const projectData = portData.projects?.[projectKey];
                
                if (!projectData) {
                    console.warn(`Project not found: ${projectKey}`);
                    return null;
                }
    
                return {
                    ...structuredClone(projectData),
                    key: projectKey,
                    sectionsToShow: projectDetails.sectionList
                };
            });
        }).filter(Boolean);
    
        return {
            name: section.displayName ?? 'Unnamed Section',
            projectData: projectList
        };
    }

    try {
        let componentName;
        if (Array.isArray(config.component)) {
            componentName = config.component[0];
        } else if (typeof config.component === 'string') {
            componentName = config.component;
        } else {
            console.error("Router could not parse component configuration");
            return {};
        }

        switch (componentName) {
            case "CircleGridGallery": {
                return processCircleGridGallery(config);
            }
            case "ScrollGallerySingleImage":
                return {
                    name: typeof config.displayName === 'string' ? config.displayName : '',
                    galleriesData: typeof config.galleries === 'object' && config.galleries !== null ? config.galleries : {}                
                };
            case "AboutMe":
                return {
                    name: typeof config.displayName === 'string' ? config.displayName : '',
                    componentProps: typeof config.componentProps === 'object' && config.componentProps !== null ? config.componentProps : {}
                };
            default:
                return {
                    name: typeof config.displayName === 'string' ? config.displayName : '',
                    galleriesData: typeof config.galleries === 'object' && config.galleries !== null ? config.galleries : {},          
                    componentProps: typeof config.componentProps === 'object' && config.componentProps !== null ? config.componentProps : {}
                };
        }
    }
    catch (error) {
        console.error(`Error in loadProps for ${type} ${id}:`, error);
        return {};
    }
};

const getDefaultMarket = (portData) => {
    if (!portData?.markets || Object.keys(portData.markets).length === 0) {
        throw new Error('No markets available');
    }

    // First check for default=true
    for (const [key, value] of Object.entries(portData.markets)) {
        if (value && typeof value === 'object' && value.default === true) {
            return key;
        }
    }

    // If no default is found, return the first key
    return Object.keys(portData.markets)[0];
};

const getDefaultSection = (portData, market) => {
    const marketData = portData.markets[market];
    if (!marketData?.sections || marketData.sections.length === 0) {
        throw new Error(`No sections available for market ${market}`);
    }
    // Return first section in the array
    return marketData.sections[0];
};


const createAppRouter = async () => {
    // Initialize store
    const portfolioStore = usePortfolioStore()

    try {
        await portfolioStore.fetchPortfolioData()
    } catch (error) {
        console.error('Failed to load initial data in router')
        throw error
    }

    const portData = portfolioStore.portfolioJson;

    // Validation helpers
    const isValidMarket = (market) => {
        const markets = Object.keys(portData.markets);
        console.log('router.js Available markets:', markets);
        console.log('router.js Checking market:', market);
        return markets.includes(market);
    }
    const isValidSection = (section, market) => {
        // First check if section exists in global sections
        const globalSections = Object.keys(portData.sections);
        if (!globalSections.includes(section)) {
            console.log('router.js Section not found in global sections:', section);
            return false;
        }

        // Then check if section is included in market's sections array
        const marketData = portData.markets[market];
        if (!marketData?.sections?.includes(section)) {
            console.log('router.js Section not found in market sections:', section);
            return false;
        }

        console.log('router.js Valid section:', section);
        console.log('router.js Available global sections:', globalSections);
        console.log('router.js Available market sections:', marketData.sections);

        return true;
    }
    const isValidProject = (project) => {
        console.log('Validating project:', project);
        console.log('Available projects:', Object.keys(portData.projects));
        return Object.keys(portData.projects).includes(project);
      };
    // Dynamic component loading
    const loadComponent = async (type, id) => {
        console.log(`router.js Loading component for type: ${type}, id: ${id}`);
        console.log('router.js Available data:', portData[type]);

        const config = portData[type][id];
        console.log('router.js Found config:', config);

        if (!config || !config.component) {
            console.error(`No component configuration found for ${type} ${id}`);
            throw new Error(`No component specified for ${type} ${id}`);
        }

        try {
            // Handle both array and string component definitions
            let componentName;
            if (Array.isArray(config.component)) {
                componentName = config.component[0];
            } else if (typeof config.component === 'string') {
                componentName = config.component;
            } else {
                throw new Error("Invalid component configuration");
            }

            // Use dynamic import with the @ alias
            return await import(`@/components/${componentName}.vue`);
        } catch (error) {
            console.error(`Failed to load component ${config.component}:`, error);
            throw error;
        }
    }

    const router = createRouter({
        history: createWebHistory(),
        routes: [
            {
                path: '/',
                redirect: `/${getDefaultMarket(portData)}` // Default market
            },
            {
                path: '/:market',
                component: AppLayout,
                beforeEnter: (to, from, next) => {
                    const portfolioStore = usePortfolioStore();
                    if (isValidMarket(to.params.market)) {
                        portfolioStore.setCurrentMarket(to.params.market); // Set market in store
                        next();
                    } else {
                        next('/404');
                    }
                },
                children: [
                    {
                        path: '',
                        redirect: to => {
                            const defaultSection = getDefaultSection(portData, to.params.market);
                            return `/${to.params.market}/${defaultSection}`;
                        }
                    },
                    {
                        path: ':section',
                        name: 'section',
                        props: true,
                        beforeEnter: (to, from, next) => {
                            if (isValidSection(to.params.section, to.params.market)) {
                                next()
                            } else {
                                next('/404')
                            }
                        },
                        component: {
                            async setup() {
                                try {
                                    const route = useRoute();
                                    const component = await loadComponent('sections', route.params.section);
                                    const props = loadProps('sections', route.params.section, portData);

                                    return () => h(component.default, props);
                                } catch (error) {
                                    console.error('Failed to load section component:', error);
                                    const ErrorView = await import('@/views/ErrorView.vue');
                                    return () => h(ErrorView.default);
                                }
                            }
                        },
                        children: [
                            {
                                path: ':project',
                                name: 'project',
                                props: true,
                                beforeEnter: (to, from, next) => {
                                    if (isValidProject(to.params.project)) {
                                        next()
                                    } else {
                                        next('/404')
                                    }
                                },
                                component: {
                                    async setup() {
                                        try {
                                            const route = useRoute();
                                            const component = await loadComponent('projects', route.params.project);
                                            const props = loadProps('projects', route.params.project, portData);
                                            
                                            return () => h(component.default, props);
                                        } catch (error) {
                                            console.error('Failed to load project component:', error);
                                            const ErrorView = await import('@/views/ErrorView.vue');
                                            return () => h(ErrorView.default);
                                        }
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: '/404',
                name: 'NotFound',
                component: NotFound
            },
            {
                path: '/:pathMatch(.*)*',
                redirect: '/404'
            }
        ]
    })

    // Add navigation guard for setting meta tags and document title
    router.afterEach((to) => {
        // Update meta tags
        updateMetaTags(to, portData);
    })

    return router;
}

export default createAppRouter