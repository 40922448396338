// main.js
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import createAppRouter from './router'

async function initApp() {
  try {
    const app = createApp(App)
    const pinia = createPinia()
    app.use(pinia)
    
    // Initialize router after pinia
    const router = await createAppRouter()
    app.use(router)
    
    await app.mount('#app')
  } catch (error) {
    console.error('Failed to initialize app:', error)
  }
}

initApp()